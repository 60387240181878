/*
 * This plug-in adds another pagination option similar to `full_numbers`, except
 * it adds ellipses around the page numbers when applicable. You can set how
 * many page numbers should be displayed with the iShowPages option.
 *
 * This plug- in extends the oStdClasses object with the following properties:
 * sPageEllipsis, sPageNumber and sPageNumbers.
 *
 * It also extends the oSettings object with the following properties:
 * _iShowPages, _iShowPagesHalf, _iCurrentPage, _iTotalPages, _iFirstPage and
 * _iLastPage.
 *
 * Note that DataTables 1.10 has this ability built in. As such, this plug-ins
 * has been marked as deprecated, but may still be useful for if you are using
 * an old version of DataTables.
 *
 * @name Ellipses
 * @summary Show ellipses in the pagination control where there is a gap in numbers
 * @deprecated
 * @author [Dave Kennedy](http://daveden.wordpress.com/)
 * @example
 *     $(document).ready(function() {
 *         $('#example').dataTable({
 *             'sPaginationType': 'three_button'
 *         });
 *     });
 */

/**
 * Custom reload button for datatables
 *
 * @example
 *    $('#table-orders').DataTable({
 *     buttons: [
 *       extend: 'reloadTable',
 *       text: 'Reload my Table Content',
 *       ajaxReload: true,
 *       locationReload: false,
 *       methodReload: callbackFunction,
 *     ]
 *    });
 */
$.fn.dataTable.ext.buttons.reloadTable = {
  text: 'Reload',
  action: function (_e, dt, _node, config) {
    if (config.locationReload) {
      location.reload();
    } else if (config.ajaxReload) {
      dt.clear().draw();
      dt.ajax.reload();
    } else if (config.methodReload) {
      dt.clear().draw();
      // Set the cache-control header so it will always revalidate data from the API
      let header = {
        'Cache-Control': 'max-age=0, must-revalidate',
      };
      config.methodReload(dt.table().node().id, header);
    }
  },
};

/**
 * Custom reload button for datatables
 *
 * @example
 *    $('#table-orders').DataTable({
 *     buttons: [
 *       extend: 'resetTable',
 *       text: 'Reset my Table Filters',
 *       ajaxReload: true
 *     ]
 *    });
 */
$.fn.dataTable.ext.buttons.resetTable = {
  text: 'Reset Search',
  action: function (_e, dt, _node, config) {
    dt.columns().every(function (col) {
      if (Object(dt.column(col).footer().lastChild).type === 'select-one') {
        $(dt.column(col).footer())[0].lastChild.selectedIndex = 0;
      } else if (Object(dt.column(col).footer().lastChild).type === 'text') {
        dt.column(col).footer().lastChild.value = '';
      }

      dt.column(col).search('');
    });

    if (config.ajaxReload) {
      // ajaxReload e.g. for the orders table, where an update of the filters is only visible after the data is reloaded
      dt.clear().draw();
      dt.ajax.reload();
    } else {
      // Only redraw the table, as the data is not reloaded for tables which are fully loaded
      dt.draw();
    }
  },
};

$.fn.dataTableExt.oPagination.three_button = {
  oDefaults: {
    iShowPages: 5,
  },
  fnClickHandler: function (e) {
    const fnCallbackDraw = e.data.fnCallbackDraw,
      oSettings = e.data.oSettings,
      sPage = e.data.sPage;

    if ($(this).is('[disabled]')) {
      return false;
    }

    oSettings.oApi._fnPageChange(oSettings, sPage);
    fnCallbackDraw(oSettings);

    return true;
  },
  // fnInit is called once for each instance of pager
  fnInit: function (oSettings, nPager, fnCallbackDraw) {
    const oClasses = oSettings.oClasses,
      oLang = oSettings.oLanguage.oPaginate,
      that = this;

    const iShowPages = oSettings.oInit.iShowPages || this.oDefaults.iShowPages,
      iShowPagesHalf = Math.floor(iShowPages / 2);

    $.extend(oSettings, {
      _iShowPages: iShowPages,
      _iShowPagesHalf: iShowPagesHalf,
    });

    const oFirst = $('<a class="' + oClasses.sPageButton + ' ' + 'oFirst">' + oLang.sFirst + '</a>');
    const oPrevious = $('<a class="' + oClasses.sPageButton + ' ' + 'oPrevious">' + oLang.sPrevious + '</a>');
    const oNext = $('<a class="' + oClasses.sPageButton + ' ' + 'oNext">' + oLang.sNext + '</a>');

    oFirst.on('click', { fnCallbackDraw: fnCallbackDraw, oSettings: oSettings, sPage: 'first' }, that.fnClickHandler);
    oPrevious.on(
      'click',
      { fnCallbackDraw: fnCallbackDraw, oSettings: oSettings, sPage: 'previous' },
      that.fnClickHandler
    );
    oNext.on('click', { fnCallbackDraw: fnCallbackDraw, oSettings: oSettings, sPage: 'next' }, that.fnClickHandler);

    // Draw
    $(nPager).append(oFirst, oPrevious, oNext);
  },
  // fnUpdate is only called once while table is rendered
  fnUpdate: function (oSettings, _fnCallbackDraw) {
    const tableWrapper = oSettings.nTableWrapper;

    // Update stateful properties
    this.fnUpdateState(oSettings);

    if (oSettings._iCurrentPage === 1) {
      $('.oFirst', tableWrapper).attr('disabled', true);
      $('.oPrevious', tableWrapper).attr('disabled', true);
    } else {
      $('.oFirst', tableWrapper).removeAttr('disabled');
      $('.oPrevious', tableWrapper).removeAttr('disabled');
    }

    if (oSettings._iTotalPages === 0 || oSettings._iCurrentPage === oSettings._iTotalPages) {
      $('.oNext', tableWrapper).attr('disabled', true);
    } else {
      $('.oNext', tableWrapper).removeAttr('disabled');
    }
  },
  // fnUpdateState used to be part of fnUpdate
  // The reason for moving is so we can access current state info before fnUpdate is called
  fnUpdateState: function (oSettings) {
    const iCurrentPage = Math.ceil((oSettings._iDisplayStart + 1) / oSettings._iDisplayLength),
      iTotalPages = Math.ceil(oSettings.fnRecordsDisplay() / oSettings._iDisplayLength);
    let iFirstPage = iCurrentPage - oSettings._iShowPagesHalf,
      iLastPage = iCurrentPage + oSettings._iShowPagesHalf;

    if (iTotalPages < oSettings._iShowPages) {
      iFirstPage = 1;
      iLastPage = iTotalPages;
    } else if (iFirstPage < 1) {
      iFirstPage = 1;
      iLastPage = oSettings._iShowPages;
    } else if (iLastPage > iTotalPages) {
      iFirstPage = iTotalPages - oSettings._iShowPages + 1;
      iLastPage = iTotalPages;
    }

    $.extend(oSettings, {
      _iCurrentPage: iCurrentPage,
      _iTotalPages: iTotalPages,
      _iFirstPage: iFirstPage,
      _iLastPage: iLastPage,
    });
  },
};

$.extend($.fn.dataTableExt.oSort, {
  'ip-address-pre': function (input) {
    const [ipAddress, bitmask] = input.split('/');

    return (
      ipAddress
        .split('.')
        .map(part => part.padStart(3, '0'))
        .join('.') +
      '/' +
      (bitmask || '32')
    );
  },
});

function dataTableColumnChange(select, e, clickedIndex, _previousValue, tableId) {
  const dt = $('#' + tableId).DataTable({ retrieve: true });
  if (clickedIndex !== null) {
    console.debug('Show/hide single table column');
    const column = dt.column(e.currentTarget[clickedIndex].value);
    column.visible(!column.visible());
  } else {
    console.debug('Reset visibility of table columns');
    dt.columns().every(column => {
      if (select.selectpicker('val').includes('' + column) || column === 0) {
        dt.column(column).visible(true);
      } else {
        dt.column(column).visible(false);
      }
    });
  }
}

function resetColumns(columns, select) {
  let visibleCols = [];
  columns.forEach(column => {
    if (column.sTitle && column.visible) {
      visibleCols.push('' + column.idx);
    }
  });
  select.selectpicker('val', visibleCols);
}

$.fn.dataTable.ext.feature.push({
  fnInit: function (settings) {
    const tableId = settings.sTableId;
    const div = $('<div/>').addClass('dataTables_column_selector');

    const insideDiv = $('<div/>');
    div.append(insideDiv);

    const select = $('<select/>', {
      name: tableId + '_fields',
      'aria-controls': tableId,
      class: 'selectpicker no-deselect',
      'data-live-search': 'true',
      'data-show-subtext': 'true',
      'data-size': '10',
      'data-non-selected-text': '- Select Columns -',
      'data-selected-text-format': 'count > 1',
      'data-dropdown-align-right': 'true',
      'data-dropup-auto': 'true',
      'data-container': 'body',
      'data-count-selected-text': '{0} columns visible',
      'data-width': '140px',
      multiple: 'true',
    });

    select.on('changed.bs.select', function (e, clickedIndex, _isSelected, previousValue) {
      dataTableColumnChange(select, e, clickedIndex, previousValue, tableId);
    });

    settings.aoColumns.forEach(column => {
      if (column.sTitle) {
        const selectItem = $('<option>').val(column.idx).text(column.sTitle);
        selectItem.data = column;
        if ((column.visible && column.bVisible !== false) || column.bVisible) {
          selectItem.attr('selected', 'true');
        }
        select.append(selectItem);
      }
    });

    const resetButton = $('<button class="btn btn-small" data-bs-toggle="tooltip" title="Reset Columns">')
      .append('<i class="fas fa-undo">')
      .on('click', () => resetColumns(settings.aoColumns, select));

    insideDiv.append(select);
    div.append(resetButton);
    select.selectpicker('refresh');

    return div[0];
  },
  cFeature: 'C',
});
